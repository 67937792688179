/* .charAxesArea {

	padding: 0px 7px;
	min-width: 14.5rem;
	display: flex;
	flex-direction: column;
	overflow: hidden;
	border-right: 2px solid rgba(224, 224, 224, 1);

}

.charAxesArea:hover {
	overflow-y: auto;
	overflow-x: hidden;
}
.charAxesArea::-webkit-scrollbar {
	width:5px/* Chrome, Safari, and Edge } */

.charAxesArea {
	/* padding: 0px 7px; */
	margin-left: 2px;
	min-width: 14.5rem;
	display: flex;
	flex-direction: column;
	overflow: hidden; 
	border-right: 2px solid rgba(224, 224, 224, 1);
	box-sizing: border-box;

	/* Reserve scrollbar space to prevent width changes */
	scrollbar-gutter: stable both-edges; 
	font-family: "Roboto-Regular", "Roboto-Medium", "Roboto-Bold", "Roboto-Light",
    -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
}

.charAxesArea:hover {
	overflow-y: auto; 
	overflow-x: hidden; 
}

.overrideContainer .dropzone-container::-webkit-scrollbar {
	width: 5px;
}

@supports (-moz-appearance: none) {
	.charAxesArea {
		margin-left: 0.5rem;
		/* Adjust as needed */
	}
}

@-moz-document url-prefix() {
	.charAxesArea {
		margin-left: 0.5rem;
		/* Adjust as needed */
	}
}
	
	/*Only to make the scroll bar look good*/
	/* .charAxesArea::-webkit-scrollbar-thumb {
		background-color: rgba(0, 0, 0, 0.3); 
		 border-radius: 10px;
	 } */
	
	/* .charAxesArea::-webkit-scrollbar-track {
		background-color: rgba(0, 0, 0, 0.1); 
	} */ 
	




.chartAxis {
	flex: 1;
	/* background-color: #eee; */
	background-color: white;
	display: flex;
	flex-direction: column;
    padding: 0 0 8px;
	/* text-align: left; */
}

.chartAxisHeader {
	position: sticky;
}

.chartAxisBody {
	flex: 1;
	/* overflow-y: hidden; */
	/* display: flex; */
	flex-direction: column;
	max-height: auto;
	/* min-height: 6em; */
	/* padding-left: 2px; */
	/* max-height: 100rem; */
	/* min-height: 100em; */

}

.axisTitle {

	color: #5d5c5c;
	font-weight: 600;
	/* padding: 10px 0.8rem  0 0.5rem; */
	/* padding: 10px 10px 0 10px; */
	text-align: left;
	margin-bottom: 6px;
}

.axisInfo {
	text-align: center;
	font-size: 11px;
	color: #999999;
	/* padding: 2px 0; */
}

.overrideContainer .dropzone-container {
	height: 90%;
	overflow-y: auto;
}