.sliderWithInput {
    width: 96%;
    display: flex;
    margin: 0 5px;
    height: 1.5rem;
    color: black;
    border: 1px solid lightgray;
    border-radius: 5px;
    padding: 0 5px;
    /* box-shadow: inset 1px 1px 1px lightgray, inset -1px -1px 1px lightgray; */
    background-color: white;
}

.inputValue {
    border: 1px solid lightgray;
    height: 1.5rem;
    line-height: 1.5rem;
    max-width: 70px;
    line-height: 1rem;
    margin-right: 5px;
    color: rgb(128, 128, 128);
    padding-right: 13px;
}

.inputValue {
    border: none;
}

.textValue {
    border-right: 1px solid lightgray;
    flex: 1;
    height: 1.5rem;
    line-height: 1.5rem;
    margin-right: 10px;
    color: rgb(128, 128, 128);
    font-size: 12px;    /*  PRAKASH 23DEC2024   */
}
