.relationPopover {
	padding: 1rem 0;
	font-size: 14px;
	min-width: 350;
}
.relationPopoverHeader {
	display: flex;
	font-size: 16px;
	font-weight: 600;
	padding: 0 1rem 0 2.5rem;
}

.relationPopoverBody {
	padding: 0 1rem;
}
.relationPopoverHeading {
	flex: 1;
	text-align: center;
}

.relationPopoverTableHeading {
	flex: 1;
	text-align: center;
	text-transform: capitalize;
}

.relationPopoverSideHeading {
	flex: 1;
	/* font-weight: 600; */
	font-style: italic;
}

.relationTablesHeading {
	display: flex;
	font-weight: 600;
	padding-top: 0.2rem;
}
.relationSelectSection {
	padding: 1.5rem 1rem 0 1rem;
}

#setButton {
	background-color: #2bb9bb;
	color: white;
	width: 5rem;
	text-transform: none;
}
#deleteButton {
	background-color: rgb(245, 40, 40);
	color: white;
	width: 5rem;
	margin-right: 3rem;
}
#cancelButton {
	background-color: rgb(167, 167, 167);
	color: white;
	width: 5rem;
	/* margin-right: 3rem; */
	text-transform: none;
}
.relationArrowList {
	margin: 1rem;
}

.relationArrow {
	display: flex;
	/* padding: 2px; */
	/* border: 1px solid lightgray; */
	/* border-radius: 4px; */
}

.relationArrow:nth-child(odd) {
	background-color: rgb(238, 238, 238);
}

.relationArrowTableName {
	flex: 1;
	text-transform: capitalize;
	display: flex;
	align-items: center;
	justify-content: center;
}
