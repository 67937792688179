.expandedHeader {
  top: 0;
  left: 0;
  width: 250px; 
  height: 100vh; 
  background-color: white;
  padding: 0;
  box-sizing: border-box;
  border-right: 1px solid #eeeeee; 
  z-index: 1; 
  padding-top: 30px;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  transition: all 0.3s;
}

.navbarLogo {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  margin-top: 0px;
  border: 1px solid transparent;
}

.navbarLogoName{
  font-weight: bold;
  margin-left: 5px;
  color: #616464;
  font-size: 20px;
}

.navbarButtonRow {
  background-color: white;
  width: 237px;
  border: 2px solid transparent;
  padding-bottom: 20px;
  padding-top: 20px;
  border-bottom: 1px solid #c7c7c7;
  text-decoration: none;
  transition: all 0.3s;
  font-size: 13px;
   
}

.navbarButton,
.navbarButton.navbarActive {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #616464;
  padding: 0;
  background-color: #ffffff;
  border: 1.5px solid transparent; 
  box-shadow: 2px 2px 2px rgb(#616164);
  transition: all 0.3s; 
  position: relative;
  font-weight: normal;
  width: 237px; 
  height: 50px;
  padding-left: 24px;
  font-size: 13px;
}

.navbarButton.navbarActive {
  background-color:  #f0f0f0;
  border-left: 1.5px solid rgb(10, 9, 9);
}

.navbarUserContainer:hover,
.navbarGroupContainer:hover,
.navbarAdminContainer:hover,
.navbarFolderContainer:hover,
.navbarHomeContainer:hover {
 background-color: #f5f5f5; 
 width: 237px; 
 height: 50px;
}

.navbarButtonText {
  margin-left: 5px;
   
  margin-top: 3px;
  border: 1px solid transparent;
}

.navbar-img{
  border: 1px solid transparent;
}

.expandedHeader .toggleNavbar,
.collapsedHeader .toggleNavbar {
  position: absolute;  
  width: 237px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 20px;
  bottom: 12px;
  border: 1px solid transparent;
  transition: all 0.3s;
}
.collapsedHeader .toggleNavbar{
  width: 64px;
  padding-right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 5px;
  transition: all 0s;
}




/* Collapsed Navbar */
.collapsedHeader {
  top: 0;
  left: 0;
  width: 75px; 
  height: 100vh; 
  background-color: white;
  padding: 0;
  box-sizing: border-box;
  border-right: 1px solid #eeeeee; 
  z-index: 1; 
  padding-top: 30px;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  font-family:Axiforma ExtraBold,system-ui,-apple-system,BlinkMacSystemFont,Segoe UI,Helvetica,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,sans-serif;
}
.collapsedHeader .navbarLogo{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  margin-top: 0px;
  border: 1px solid transparent;
}

.collapsedHeader .navbarLogoName{
  display: none;
}

.collapsedHeader .navbarButtonRow {
  background-color: white;
  width: 64px;
  border: 2px solid transparent;
  padding-bottom: 20px;
  padding-top: 20px;
  border-bottom: 1px solid rgb(199, 199, 199);
  text-decoration: none;
}

.collapsedHeader .navbarButton,
.collapsedHeader .navbarButton.navbarActive {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #616464;
  padding: 0;
  background-color: #ffffff;
  border: 1.5px solid transparent; 
  box-shadow: 2px 2px 2px rgb(#616164);
  transition: all 0.3s; 
  position: relative;
  font-weight: normal;
  width: 64px; 
  height: 50px;
  padding-left: 24px;
}

.collapsedHeader .navbarButton.navbarActive { 
  width: 65.8px;
  border-left: 1.5px solid rgb(10, 9, 9);
  background-color:  #f0f0f0;
}

.collapsedHeader .navbarButtonText {
  display: none;
}
.collapsed span{
  display: none;
}
.expandCollapsed{
  height: 50px;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;

}
.expandCollapsed:hover{
  background-color: #ececec;
}