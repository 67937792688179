
.header {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  /* position: fixed; */
  top: 0;
  width: 100%;
  height: 35px;
  background-color: white;
  padding: 0 ;
  box-sizing: border-box;
  box-shadow: 0px 0px 0px rgba(0,0,0,0.1);
  /* border-bottom: 1px solid#eeeeee; */
}

.heading {
  margin: 0;
  margin-right:760px ;
  color:black;
}
.buttonRow {
  display: flex;
  gap: 10px;
  margin: 0 20px;
}

.button {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #616464;
  padding: 5px 10px;
  background-color: #ffffff;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.button:hover {
  background-color: #ffffff;
}

.buttonText {
  margin-left: 5px;
}

.nav {
  display: flex;
  align-items: center;
}

.homeButton {
  display: flex;
  align-items: center;
  margin-right: 10px;
  text-decoration: none;
  color: black;
}
.admin {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  overflow: hidden;
}
.adminImage {
  border-radius: 50%;
}
.submenu {
  position: absolute;
  top: 100%;
  right: 0;
  background-color: white;
  border: 1px solid #ddd;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 100000;
  display: flex;
  flex-direction: column;
}

.submenuItem {
  padding: 10px 20px;
  white-space: nowrap;
  color: #616164;
  text-decoration: none;
  font-size:14px;
}
.submenuImg{
  margin-bottom:-4px;
  margin-left:-3px;
}

.submenuItem:hover  {
  background-color: #f1f1f1;
  font-weight:700;
} 

.header-name-email-container{
  border: 1px solid red;
}

