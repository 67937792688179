.groupName-buttonContainer {
  margin-top: 50px;
  margin-bottom: 22px;
  height: 40px;
  display: flex;
  border: 1px solid transparent;
  height: 39.33px;
}

.groupName-button-group:hover{
  /* background-color: #eeeeee; */
  background-color: #2bb9bb;
  color: whitesmoke;
} 
.groupName-addIconHover {
  display: none;
}

.groupName-button-group:hover .groupName-addIconDefault {
  display: none;
}

.groupName-button-group:hover .groupName-addIconHover {
  display: block;
} 
.groupName-button-group img {
  margin-right: 5px;
  margin-bottom: 0px;
}


/* Table */
 .groupName-groupsTable th:nth-child(1):hover,
 .groupName-groupsTable th:nth-child(2):hover {
  background-color: #eeeeee;
}

.groupName-groupsTable th:nth-child(1), 
.groupName-groupsTable td:nth-child(1) {
  width: 40%; 
  box-sizing: border-box;
}

.groupName-groupsTable th:nth-child(1){
  padding-left: 35px;
}

.groupName-groupsTable td:nth-child(1){
  text-align: left;
  padding-left: 10px;
}

.groupName-groupsTable th:nth-child(2), 
.groupName-groupsTable td:nth-child(2) {
  width: 40%;
  box-sizing: border-box;
}
.groupName-groupsTable td:nth-child(2){
  text-align: left;
  padding-left: 6px;
}

.groupName-groupsTable th:nth-child(3), 
.groupName-groupsTable td:nth-child(3) {
  width: 20%;
  box-sizing: border-box;
}

.groupName-groupsTable th:nth-child(3){
  text-align: center;
  padding-right: 6px;
}

.groupName-groupsTable td:nth-child(3) {
  width: 19.5%;
  box-sizing: border-box;
}

.userRow {
  background-color: #f9f9f9;
}

.hoveredRow tr {
  background-color: #484848;
}


.groupName-modal-content {
  border: 1px solid transparent;
  height: 150px;
  width: 400px;
  border-radius: 6px;
}

.groupName-modalContent-close {
  border: 1px solid transparent;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 5px;
  margin-right: 5px;
}

.groupName-modalContent-p {
  border: 1px solid transparent;
  color: #484848;
  font-size: 0.75rem;
  vertical-align: middle;
  text-align: center;
  margin-top: -5px;
}

.groupName-modalContent-p p{
  margin-top: 0px;
  margin-bottom: 10px;
}

.groupName-create-group-input{
  border: 1px solid transparent;
  display: flex;
  align-items: center;
  justify-content: center;
}

.groupName-input{
  height:30px;
  width: 280px;
  border-radius: 4px;
  border: 1px solid #eeeee9;
}

.groupName-input:focus{
  outline: none;
  border: 1px solid #2bb9bb;
}

.groupName-modal-create{
  color:#2bb9bb;
  border:1px solid #2bb9bb;
}
.groupName-modal-create:hover{
  color:white;
  background-color: #2bb9bb;
  border:1px solid #2bb9bb;
}


.groupName-userItem img {
    margin-right: 20px;
    margin-left: 50px;
}

.groupName-close:hover,
.groupName-close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
}

.groupName-headerIcon{
  position: absolute;
  bottom: 14px; 
  width: 20px;
  height: 18px;
}




.workspaceModalContent {
  background: white;
  padding: 15px;
  border-radius: 8px;
  text-align: center;
  width: 350px;
  min-height: 165px;
  max-height: 400px;
  overflow-y: auto;
  transition: height 0.3s ease;
}

.workspaceCloseContainer {
  border: 1px solid transparent;
  text-align: end;
  transform: translate(8px, -8px);
}

.workspaceClose {
  cursor: pointer;
}

.workspaceModalContent p {
  border: 1px solid transparent;
  margin-top: 10px;
  margin-bottom: 10px;
}
.workspaceModalContent h3 {
  margin-top: 5px;
  margin-bottom: 8px;
  color: rgba(0, 0, 0, 0.748);
  justify-content: center;
  text-align: center;
  border: 1px solid transparent;
}

.workspaceModalButtons {
  border: 1px solid transparent;
  display: flex;
  flex-wrap: wrap;
  /* justify-content: space-between; */
  /* gap: 10px; */
  justify-content: space-around;
}

.workspaceModalContent button {
  /* margin-right: 50px;
  margin-left: 40px; */
  padding: 8px 16px;
  /* flex: 1; */
}
.workspaceModalContent .workspaceModalCancel {
  border-radius: 4px;
  border: 1px solid transparent;
  background-color: gray;
  color: white;
  cursor: pointer;
}
.workspaceModalContent .workspaceModalCancel:hover {
  background-color: rgb(104, 104, 104);
}
.workspaceModalContent .workspaceModalConfirm {
  background-color: #f44336;
  border: 1px solid transparent;
  border-radius: 4px;
  color: white;
}
.workspaceModalContent .workspaceModalConfirm:hover {
  background-color: #d32f2f;
  color: white;
}
.workspaceModalContent .workspaceSaveButton {
  border-radius: 4px;
  border: 1px solid #26acaf;
  background-color: #2bb9bb;
  /* border: 1px solid #388E3C;
  background-color: #4CAF50; */
  /* border: 1px solid #D32F2F;
  background-color: #F44336; */
  color: white;
  cursor: pointer;
  margin-left: 35px;
}

.workspaceModalContentInput {
  border: 1px solid transparent;
  height: 40px;
  margin-top: 5px;
  margin-bottom: 18px;
}
.workspaceModalContent input {
  width: 280px;
  padding: 10px;
  color: #616164;
  border: 1px solid #2e302e;
  border-radius: 3px;
}
.workspaceModalContent input:focus {
  border: 1px solid #2db4ae;
  outline: none;
}