.shared-links-header-close-container{
  border: 1px solid transparent;
  display: flex;
  flex-direction: row-reverse;
  height: 50px;
  justify-content: space-between;
}
.shared-links-header-close-container h2{
  margin: 0;
  padding: 0;
  margin-left: 20px;
  border: 1px solid transparent;
}
.expire-link-close{
  border: 1px solid transparent;
  margin-right: 5px;
  margin-top: 5px;
}
.link-expire-date-copy-container{
  width: 100%;
}
.link-expire-date{
  /* border: 3px solid rgb(123, 95, 213);  */
  /* display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column; */
  margin-bottom: 10px;
  margin-top: 10px;
  border-radius: 4px;
  padding: 5px 15px 10px 15px;
  background-color: rgba(175, 153, 219, 0.1);
}

.link-expire-span{
  border: 1px solid transparent;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  margin-top: 10px;
}
.link-expire-sapn-link{
  font-weight: bold;
}
.share-link-copy-paste-container{
  border: 1px solid transparent;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between
}

.copy-container {
  position: absolute;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.copy-container .copy {
  background-color: #2bb9bb;
}

.copy,
.copied{
  width: 26px;
  height: 24px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  transition: 0.4s width;
  transform-origin: left;
  overflow: hidden; 
  position: relative;
  cursor: pointer;
}
.copy img{
  width: 18px !important;
  height: 15px !important;
  color: white;
  font-weight: bolder;
  margin-left: 6.5px;
}
.copy:hover {
  width: 70px;
  transition: 0.4s width;
  transform-origin: left;
  /* background-color:  rgb(212, 212, 212); */
  background-color: #2bb9bb;
  border: 1px solid transparent;
}

.copy span {
  opacity: 0;
  margin-left: 8px;
  white-space: nowrap;
  font-weight: 600;
  border: 1px solid transparent;
}
.copy:hover span {
  opacity: 1;
}
.copied{
  width: 70px;
  /* background-color:  rgb(212, 212, 212); */
  background-color: #2bb9bb;
  border: 1px solid transparent;
}
.copied .tick-icon{
  width: 14px;
  height: 10px;
  margin-left: 6px;
  border: 1px solid transparent;
  color: white;
  font-weight: bolder;
  margin-left: 6.5px;
  
}
.copied span{
  margin-left: 4px;
  white-space: nowrap;
  font-weight: 600;
  border: 1px solid transparent;
}