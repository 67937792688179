
.dashboardWrapper {
  flex: 1;
  display: flex;
  background-color: #eee;
  overflow: hidden;
}

.dashboardOuter {
  box-sizing: border-box;
  background-color: #fff;
  flex: 1;
  overflow: hidden;
  margin-left: 5px;
}

.dashboardArea {
  box-sizing: border-box;
  border: 1px solid #d3d3d3;
  border-top: none;
  overflow: hidden;
}

.dashBoardSideBar {
  width: 15.625rem;
  height: 100%;
  border-left: 2px solid rgba(224, 224, 224, 1);
  display: flex;
  flex-direction: column;
  background-color: white;
  padding-right: 6px;
  padding-left: 6px;
}

.tileListContainer {
  flex: 1;
}

.dashboardLayoutControl {
  flex: 1;
}

.listOfGraphs {
  display: flex;
  margin: 5px 5px;
  /* background-color: rgba(246, 246, 246); */
  border: 1px solid rgb(224, 224, 224, 1);
  border-radius: 2px;
  line-height: 1.5rem;
  color: #606060;
}

.listOfGraphsHighlighted {
  display: flex;
  margin: 5px 5px;
  background-color: #fff;
  border: 1px solid #0076f6;
  line-height: 1.5rem;
  color: #606060;
}

.graphCheckBox {
  margin: 5px;
}
.checkbox_tick {
  appearance: none; /* Removes default checkbox styles */
  width: 16px;
  height: 16px;
  border: 2px solid #2bb9bb;
  border-radius: 3px;
  background-color: transparent;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex-shrink:0
}

.checkbox_tick:checked {
  background-color: #2bb9bb;
  border-color: #2bb9bb;
}

.checkbox_tick:checked::before {
  content: "✔";
  font-size: 12px;
  color: white;
  font-weight: bold;
  line-height: 1;
}


.checkbox-container {
  text-align: left;
  margin-left: 23px;
  color: #616164;
  font-size: 13px;
  margin-bottom: 15px;
  margin-top: 17px;
  accent-color: #2bb9bb;
}

.checkbox-label {
  margin-left: 5px;
  cursor: pointer;
}

.themeSelector {
  font-family: Arial, sans-serif;
  font-size: 16px;
}

.checkboxOption {
  margin: 5px 0;
}

.checkboxOption input {
  margin-right: 10px;
}

.dragHeader {
  font-weight: 600;
  color: #929292;
  /* border-bottom: 1px solid rgb(211, 211, 211); */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  background-color: white;
}

.dragHeader:hover {
  border-bottom: 1px solid rgb(211, 211, 211);
}

.dashChart {
  width: 100%;
  flex: 1;
}

.rndObject {
  box-sizing: border-box;
  /* border: 1px #fff solid; */
  width: 100%;
  height: 100%;
  /* display: flex;
    flex-direction: column; */
  overflow: auto;
  box-sizing: border-box;
  
}


/* Style the container */
.checkboxOption {
  margin-left: 25px;
  margin-bottom: 15px;
  font-size: 15px;
  position: relative;
  display: flex;
  align-items: center;
}

/* Hide the default radio button */
.customRadio {
  display: none;
}

/* Style the label to act as a custom radio button */
.customLabel {
  position: relative;
  padding-left: 30px;
  cursor: pointer;
  color: #333333;
}

/* Create the custom radio circle */
.customLabel::before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 15px;
  height: 15px;
  border: 2px solid #616164;
  border-radius: 50%;
  background-color: white;
  transition: all 0.3s ease;
}

/* Inner circle when selected */
.customLabel::after {
  content: "";
  position: absolute;
  left: 6px;
  top: 50%;
  transform: translate(-32%,-50%);
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background-color: transparent;
  transition: all 0.3s ease;
}

/* Change color of the custom radio when checked */
.customRadio:checked + .customLabel::before {
  border-color: #2bb9bb;
}

.customRadio:checked + .customLabel::after {
  background-color: #2bb9bb;
}
