.user-description {
  margin-top: 5rem;
  margin-bottom: 40px;
  padding-top: 30px;
  font-size: 16px;
  font-size: 14px;
  text-align: center;
  color: #484848;  
}

.user-button:hover,
.user-button1:hover {
  /* background-color: #eeeeee; */
  background-color: #2bb9bb;
  color: white;
}

.user-addIconHover {
  display: none;
}

.user-button:hover .user-addIconDefault,
.user-button1:hover .user-addIconDefault {
  display: none;
}

.user-button:hover .user-addIconHover,
.user-button1:hover .user-addIconHover {
  display: block;
}

.user-linkButton {
  text-decoration: none;
}

.user-button img,
.user-button1 img {
  margin-right: 15px;
  margin-bottom: 3px;
}

.user-userRow:hover {
  background-color: red;
}

.user-verticalLine {
  width: 1px;
  height: 30px;
  background-color: #616164;
  margin: 10px 10px;
}

.user-actionButton:hover {
  background-color: #4d4d4d;
}

.user-modal {
  display: block;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);
}

.user-modal-content {
  background-color: #fefefe;
  margin: 15% auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
  max-width: 400px;
  border-radius: 4px;
}

.user-modal-content p {
  font-size: 16px;
  margin-bottom: 10px;
  color: #616164;
  text-align: center;
}

.user-modal-content input[type="text"] {
  position: relative;
  left: 10px;
  width: calc(100% - 40px);
  padding: 10px;
  margin-top: 15px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.user-modal-content button {
  background-color: #ffffff;
  color: #2bb9bb;
  border: 1px solid #2bb9bb;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  margin-left: 130px;
}
.user-close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
  cursor: pointer;
}

.user-close:hover,
.user-close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.user-modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
}

.user-modal-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  width: 80%;
  max-width: 500px;
  position: relative;
}
.user-modal-buttons {
  display: flex;
  justify-content: space-between;
}
.user-modal-buttons .user-modal-cancel {
  margin-left: 25px;
  border: 1px solid #b0b0b0;
  background-color: #f0f0f0;
  color: #333333;
}
.user-modal-buttons .user-modal-cancel:hover{
  color: white;
  background-color: #333333;
}
.user-modal-buttons .user-modal-confirm {
  position: absolute;
  right: 40px;
  /* border: 1px solid #388E3C;
  background-color: #4CAF50; */
  border: 1px solid #d32f2f;
  background-color: #f44336;
  color: white;
}

.user-modal-buttons .user-modal-confirm-deactivate {
  position: absolute;
  right: 40px;
  border: 1px solid #e97f00;
  background-color: #fd7e14;
  color: white;
}

.user-modal-buttons .user-modal-confirm-activate {
  position: absolute;
  right: 40px;
  border: 1px solid #26acaf;
  background-color: #2bb9bb;
  color: white;
}
.user-modal-buttons .user-modal-create {
  border: 1px solid #26acaf;
  background-color: #2bb9bb;
  color: white;
  position: absolute;
  right: 45px;
}

.user-remove-modalCancel {
  border: 1px solid #484848;
  color: #484848;
}

.user-modal-content h3 {
  margin-top: 10px;
  margin-bottom: 10px;
  color: rgba(0, 0, 0, 0.748);
  justify-content: center;
  text-align: center;
}
.user-close {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 28px;
  cursor: pointer;
}

/* Table */
.user-userTable th:nth-child(3):hover,
.user-userTable th:nth-child(4):hover,
.user-userTable th:nth-child(5):hover {
  background-color: #eeeeee;
}

.user-userTable th:nth-child(1),
.user-userTable td:nth-child(1) {
  width: 5%;
  box-sizing: border-box;
  display: none;
}

.user-userTable th:nth-child(3) {
  padding-left: 30px;
}

.user-userTable th:nth-child(2),
.user-userTable td:nth-child(2) {
  width: 5%;
  box-sizing: border-box;
  padding-top: 7px;
}

.user-userTable th:nth-child(3),
.user-userTable td:nth-child(3) {
  width: 30%;
  box-sizing: border-box;
}

.user-userTable td:nth-child(3) {
  text-align: left;
}

.user-userTable th:nth-child(4),
.user-userTable td:nth-child(4) {
  width: 30%;
  box-sizing: border-box;
}

.user-userTable td:nth-child(4) {
  text-align: left;
}

.user-userTable th:nth-child(5),
.user-userTable td:nth-child(5) {
  width: 15%;
  box-sizing: border-box;
  text-align: center;
}

.user-userTable th:nth-child(6) {
  width: 15%;
  box-sizing: border-box;
  text-align: center;
}
.user-userTable td:nth-child(6) {
  width: 14.4%;
  box-sizing: border-box;
}

.user-userTable tbody tr {
  background-color: #fff; /* Default background color for all rows */
}

/*.user-userTable tbody tr:nth-child(odd) {
    background-color: #f0f0f0;  Background color for odd rows 
} 

.user-userTable tbody tr:hover {
    background-color: #b9b9b9;  Background color on hover 
} */


.user-img-icon {
  border: 1px solid transparent;
  display: flex;
  align-items: center;
  justify-content: center;
}
.user-img-icon img {
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  margin-left: 5px;
  margin-right: 5px;
}
.placeholder {
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  margin-left: 5px;
  margin-right: 15px;
  font-family: "Axiforma Light", sans-serif;
}


/* Folder Ribbon */
.heading {
  display: flex;
  border: 1px solid transparent;
  height: 50px;
  margin-left: 15px;
  padding-top: 5px;
}

.heading h3 {
  color: #616164;
  margin-top: 3px;
  margin-bottom: 0;
  margin-left: 15px;
}
