.commonTile {
  transition: 0.2s;
  display: inline-block;
  /* height: 1.5rem; */
  line-height: 1.3rem;
  /* margin-left: 2px; */
  margin: 0 4px 0 4px;
  padding: 1.5px 5px 0 5px;
  cursor: pointer;
  background-color: rgb(211, 211, 211);
  height: 24.8px;
  position: relative;
}
.commonTile:hover {
  color: rgb(64, 64, 64);
  background: white;
}
.plusTile {
  color: rgb(96, 96, 96);
  padding: 0 5px;
  background: transparent;
  border: 2px solid rgba(224, 224, 224, 1);
  margin-left: 4px;
}
.indiItemTile {
  color: rgb(128, 128, 128);
  background-color: white;
  border: 1px solid rgba(224, 224, 224, 1);
  /* border-bottom: 2px solid transparent;
	background-color: transparent; */
}
.indiItemHighlightTile {
  /* transition: 0.2s;
	color:black; */
  /* border-bottom: 2px solid #0076f6; */
  border: 1px solid rgba(224, 224, 224, 1);
  background: rgba(224, 224, 224, 1);

}
.tabText {
  /* display: inline-block; */
  margin: 3px 10px 0px 0px;
  /* margin-right: 10px; */
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 12px;
}
.editTileSelected {
  transition: 0.2s;
  display: inline-block;
  height: 1.5rem;
  line-height: 1.5rem;
  margin-left: 2px;
  padding: 0 0 0 10px;
  border-bottom: 2px solid #2bb9bb;
  border: none;
  background-color: white;
  font-size: 12px;
}
.editTileSelected:focus {
  outline: none;
  border-bottom: 2px solid #2bb9bb;
}
.closeTile {
  /* border-radius: 2px; */
  /* margin-left: 5px; */
  /* padding: 0px 5px; */
  color: #606060;
  font-size: 13px;
  cursor: pointer;
  position: relative;
}
.closeTile:hover {
  background-color: rgb(238, 238, 238);
  color: rgb(255, 0, 0);
}
.close-container {
  position: absolute; 
  display: flex;
  right: 5px; 
  top: 55%; 
  transform: translateY(-50%);
}

/* Separate styling for IndividualTile components within the popup list */
.popupTile {
  border: none;
  width: 224.5px;
  margin-left: 0px;
  margin-right: 0px;
  height: 35px;
  padding-left: 15px;
  padding-right: 0px;
  display: flex;
  align-items: center;
  border: 1px solid transparent;
}
.popupTile:hover {
  height: 35px;
  border: 1px solid gray;
}
.popupClose,
.hidden {
  color: #606060;
  font-size: 9px;
  position: absolute;
  opacity: 0; /* Initially hide the close icon */
  transition: opacity 0.2s;
  right: 17px; 
  top: 35%;
  transform: translateY(-50%);
  background-color: transparent;
  height: 11.73px ;
  width: 5.31px;
}
.popupTile:hover .popupClose, 
.popupTile:focus .popupClose  {
 opacity: 1;
 background-color: transparent;
} 
.hidden {
  display: visible;
  opacity: 1;
  color:  #606060;
  font-size: 9px;
  position: absolute;
  right: 17px; 
  top: 35%;
  transform: translateY(-50%);
  background-color: transparent;
  height: 11.73px ;
  width: 5.31px;
} 

.top-auto{
  top:auto !important
}