.groupNameHeader {
  color: #616161;
  font-size: 20px;
  border: 1px solid transparent;
  height: 30px;
}

.group-button-user:hover {
  background-color: #2bb9bb;
  color: whitesmoke;
}

.group-addIconHover {
  display: none;
}

.group-button-user:hover .group-addIconDefault {
  display: none;
}

.group-button-user:hover .group-addIconHover {
  display: block;
}

.group-button-user img {
  margin-right: 15px;
  margin-bottom: 0px;
}

.hoveredRow .nameHyperlink {
  text-decoration: underline;
  color: #2bb9bb;
}

.nameHyperlink {
  transition: color 0.3s ease, text-decoration 0.3s ease;
}

.nameHyperlink:hover {
  text-decoration: underline;
  color: #2bb9bb;
}

/* Table */
.group-userTable th:nth-child(3):hover,
.group-userTable th:nth-child(4):hover {
  background-color: #eeeeee;
}

.group-userTable th:nth-child(1),
.group-userTable td:nth-child(1) {
  width: 5%;
  box-sizing: border-box;
  display: none;
}

.group-userTable td:nth-child(1) {
  padding-right: 5px;
}

.group-userTable th:nth-child(2),
.group-userTable td:nth-child(2) {
  width: 5%;
  box-sizing: border-box;
  padding-top: 7px;
}

.group-userTable th:nth-child(3),
.group-userTable td:nth-child(3) {
  width: 35%;
  box-sizing: border-box;
}

.group-userTable td:nth-child(3) {
  text-align: left;
}

.group-userTable th:nth-child(4),
.group-userTable td:nth-child(4) {
  width: 40%;
  box-sizing: border-box;
}

.group-userTable td:nth-child(4) {
  text-align: left;
}

.group-userTable th:nth-child(5) {
  width: 15%;
  box-sizing: border-box;
  text-align: center;
  padding-right: 0px;
}

.group-userTable td:nth-child(5) {
  width: 14.5%;
  box-sizing: border-box;
}

.group-userTable tr td img {
  height: 30px;
}

.disabled-text {
  color: #eeeee1;
}

.group-detail-headerIcon {
  position: absolute;
  right: 10px;
  bottom: 14px;
  width: 20px;
  height: 18px;
}

.group-userTable tbody tr {
  background-color: #fff; /* Default background color for all rows */
}

/*.group-userTable tbody tr:nth-child(odd) {
  background-color: #f0f0f0;  Background color for odd rows 
} 

.group-userTable tbody tr:hover {
  background-color: #b9b9b9;  Background color on hover 
} */

/* Breadcrumb */
.group-Header {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border: 1px solid transparent;
}
.group-back-link {
  background-color: #fff;
  border: none;
  color: #616164;
  cursor: pointer;
  text-wrap: nowrap;
}
.group-home-icon {
  position: relative;
  top: 3px;
  margin-right: 8px;
}

.group-home-text {
  font-size: 14px;
  color: #616164;
}
.groupName {
  background-color: #fff;
  border: none;
  color: #616164;
  text-wrap: nowrap;
  margin-top: 7px;
}

/* Modal Content Table */
.group-userList th:nth-child(1),
.group-userList td:nth-child(1) {
  width: 10%;
  box-sizing: border-box;
  text-align: center;
  vertical-align: middle;
}

.group-userList th:nth-child(1) {
  padding-top: 3px;
}

.group-userList th:nth-child(2),
.group-userList td:nth-child(2) {
  width: 10%;
  box-sizing: border-box;
  text-align: center;
}

.group-userList th:nth-child(3),
.group-userList td:nth-child(3) {
  width: 40%;
  box-sizing: border-box;
  text-align: left;
}

.group-userList td:nth-child(3) {
  text-align: left;
}

.group-userList th:nth-child(4),
.group-userList td:nth-child(4) {
  width: 40%;
  box-sizing: border-box;
  text-align: left;
}

.group-userList th:nth-child(3):hover,
.group-userList th:nth-child(4):hover {
  background-color: #eeeeee;
}
