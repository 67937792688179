*,
body {
  font-family: "Roboto-Regular", "Roboto-Medium", "Roboto-Bold", "Roboto-Light",
    -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
}
.headerContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  background-color: #ffff;
  padding: 10px;
  padding-left: 15px;
  color: #646161;
  border: 1px solid transparent;
  height: 2rem;
  /* margin-top: -5px; */
}
.backLink {
  width: 75px;
  height: 24px;
  background-color: #fff;
  border: none;
  color: #616164;
  font-size: 15.8px;
  /* background-image: url("../../public/back.png"); */
  /* background-repeat: no-repeat;
  background-size: 20px 20px; */
  cursor: pointer;
  padding-left: 5px;
  padding-top: 5px;
  /* background-position: 2px center; */
  cursor: pointer;
}
.header {
  text-align: center;
  width: 100%;
  color: #616164;
}
.accessHead {
  background-color: #ac9bf8;
  color: whitesmoke;
}
.heading {
  padding-top: 5px;
  margin-left: 15px;
  display: flex;
  height: 50px;
  border: "1px solid transparent"
}

.heading h4 {
  margin-top: 8px;
  margin-left: 15px;
  color: #616164;
}

.heading p {
  position: relative;
  bottom: 8px;
  margin-left: 15px;
  color: #616164;
}
.dropdown {
  position: relative;
  display: inline-block;
  color: #616164;
}

.dropdownContent {
  display: none;
  position: absolute;
  color: #616164;
  background-color: white;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 1;
  min-width: 150px;
}

.createWorkspace {
  padding: 8px 15px;
  height: 28px;
  width: 100px;
  color: #af99db;
  border: 1px solid #af99db;
  font-size: 13px;
  display: flex;
  align-items: center;
  background-color: white;
  border-radius: 4px;
  /* padding: 8px; */
  cursor: pointer;
  text-decoration: none;
  text-wrap: nowrap;
}

.createWorkspace:hover {
  background-color: #af98db;
  color: white;
}
.adminAddIconHover {
  display: none;
}

.createWorkspace:hover .adminAddIconDefault {
  display: none;
}

.createWorkspace:hover .adminAddIconHover {
  display: block;
}

.createWorkspace img,
.adminAddIconHover img {
  margin-right: 15px;
  margin-bottom: 0px;
}

.dropdownContent button {
  background: none;
  border: none;
  padding: 8px 16px;
  width: 100%;
  text-align: left;
  cursor: pointer;
}

.dropdownContent button:hover {
  background-color: #f1f1f1;
}

.dropdown:hover .dropdownContent {
  display: block;
}

.actionButtons {
  display: flex;
  position: absolute;
  gap: 5px;
  margin-left: 15px;
  margin-top: 10px;
}
.noAccessMessage {
  text-align: center;
  color: #616164;
  font-size: 14px;
}

.roleDropdown {
  border: 1px solid #ddd;
  height: 30px;
  border-radius: 5px;
  color: #616164;
  text-align: left;
}

.select.roleDropdown option:hover {
  background-color: #af99db; /* Desired hover background color */
  color: white; /* Optional: Adjust text color */
}

.disabledOption {
  color: #ccc;
  cursor: not-allowed;
  pointer-events: none;
}

.modal {
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
}

.modalContent {
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  width: 60%;
  max-width: 800px;
  align-items: center;
}

.reloadButton {
  /* border: 1px solid #616164;
    color:#616164; */
  border: 1px solid #b0b0b0;
  background-color: #f0f0f0;
  color: #333333;
  border-radius: 3px;
  cursor: pointer;
  padding: 8px 25px;
  /* background-color: white; */
  margin-right: 30px;
}
.okButton {
  border: 1px solid #af99db;
  color: white;
  background-color: #af99db;
  cursor: pointer;
  border-radius: 3px;
  padding: 8px 30px;
  /* background-color: white; */
}
.addButton {
  border: 1px solid #2bb9bb;
  color: #2bb9bb;
  border-radius: 3px;
  padding: 8px 30px;
  background-color: white;
  align-items: center;
  margin-top: 30px;
}

.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.resourcesTableContainer {
  margin-top: 80px;
}
.table {
  padding-left: 100px;
}

.resourcesTable {
  width: 100%;
  border-collapse: collapse;
  font-size: 0.75rem;
}
/* .stateButton{
    display: flex;
  } */
.resourcesTable th {
  background-color: grey;
  color: white;
}

.resourcesTable thead {
  position: sticky; /* Fix the header */
  top: 0; /* Keep it at the top */
  left: 0;
  z-index: 1; /* Ensure it stays on top of the content */
}

.iconButton {
  background-color: transparent; /* No background */
  border: none; /* No border */
  cursor: pointer; /* Pointer cursor on hover */
}
.resourcesTable th,
.resourcesTable td {
  padding: 10px;
  border: 1px solid #ddd;
  text-align: center;
}

.resourcesTable th[colspan="3"] {
  text-align: center;
}

.resourcesTable th[rowspan="3"] {
  vertical-align: middle;
}

.resourcePermissions {
  display: flex;
  justify-content: space-around;
}

.resourcePermissions input[type="checkbox"] {
  margin: 0 5px;
}

/* Modal Content */
.accessModal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid transparent;
  overflow: hidden;
}
.accessModalContent {
  background-color: white;
  border-radius: 10px;
  height: 90vh;
  width: 620px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
  padding-bottom: 20px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
}

/* Modal Content Header*/
.accessHeaderSection {
  border: 1px solid transparent;
}

/* Modal Content Searchbar */
.accessInputContainer {
  border: 1px solid transparent;
  display: flex;
  justify-content: flex-end;
}
.modalContentCheckboxContainer {
  border: 1px solid rgb(203, 203, 203);
  border-radius: 4px;
  width: 32px;
  margin-right: 336px;
  display: flex;
  flex-direction: row;
}
.modalContentCheckbox {
  margin-top: 6px;
  margin-left: 6.6px;
}
.accessModalContent .access-searchInput {
  position: relative;
  margin-right: 30px;
}
.accessModalContent .access-searchIcon {
  position: absolute;
  transform: translate(-40px, 4px);
}

/* Modal Content Title */
.accessModalTitle {
  border: 1px solid transparent;
  display: flex;
  justify-content: center;
}

/* Modal Content Table */
.accessPageNoUserInfo, .accessTableContainer {
  flex-grow: 1;
  background-color: none;
  margin-top: 10px;
  border: 1px solid white;
  table-layout: fixed;
  width: 620px;
  padding: 0px 10px 0px 11px;
  overflow-y: auto;
  overflow-x: hidden;
  box-sizing: border-box;
}

.accessUserList th,
.accessUserList td {
  height: 25px;
  padding: 5px;
  border-bottom: 1px solid #ddd;
  vertical-align: middle;
  text-align: center;
  box-sizing: border-box;
}
.accessUserList {
  background-color: white;
  border-collapse: collapse;
  table-layout: fixed;
  width: 100%;
  max-height: 200px;
  overflow-y: auto;
}

.accessModalHeader {
  width: 100%;
  background-color: #af98db;
  height: 30px;
  border-collapse: unset;
  box-sizing: border-box;
}
.accessUserList thead th {
  position: sticky;
  top: 0;
  z-index: 10000;
  border: 1px solid transparent;
  table-layout: fixed;
  background-color: #af98db;
  height: 30px;
  border: 1px solid white;
  box-sizing: border-box;
}
.accessUserList tbody tr:hover {
  background-color: #f1f1f1;
}

.accessUserList th:nth-child(1),
.accessUserList td:nth-child(1) {
  width: 10%;
  box-sizing: border-box;
}

.accessUserList th:nth-child(2),
.accessUserList td:nth-child(2) {
  width: 15%;
  box-sizing: border-box;
  padding-top: 7px;
}

.accessUserList th:nth-child(3),
.accessUserList td:nth-child(3) {
  width: 35%;
  box-sizing: border-box;
  text-align: left;
}

.accessUserList th:nth-child(4),
.accessUserList td:nth-child(4) {
  width: 40%;
  box-sizing: border-box;
  text-align: left;
}

/* Container to make the table scrollable */
.resourcesTable th[colspan="3"] {
  text-align: center;
}

.resourcesTable th[rowspan="3"] {
  vertical-align: middle;
}
.accesstableContainer {
  /* flex-grow: 1; */
  width: 100%;
  margin-bottom: 50px;
  flex-grow: 1;
  width: 100%;
  border: 1px solid white;
  height: 60vh;
  overflow-y: auto;
  overflow-x: hidden;
}

/* Table base styles */
.resourcesTable {
  border-collapse: collapse;
  font-size: 0.75rem;
  width: 98%;
  margin: auto;
  max-height: 100%;
  overflow-y: auto;
  /* margin-left: 15px;
  margin-right: 15px; */
  position: sticky;
  border: 1px solid transparent;
  box-sizing: border-box;
}

/* Header cells */
.resourcesTable thead {
  height: 30px;
  border: 1px solid transparent;
  box-sizing: border-box;
}
.resourcesTable th {
  background-color: grey;
  color: white;
  padding: 8px;
  font-weight: bold;
  position: sticky;
  height: 30px;
  font-size: 13px;
  text-align: center;
  z-index: 0;
  overflow: hidden;
  vertical-align: middle;
  box-sizing: border-box;
  border: 1px solid #ddd;
}
.resourcesTable tr {
  box-sizing: border-box;
}
/* Body cells */
.resourcesTable tbody {
  border: 1px solid transparent;
  overflow-x: hidden;
  box-sizing: border-box;
  overflow: auto;
  overflow-y: auto;
}

.resourcesTable td {
  border: 1px solid #ddd;
  text-align: center;
  padding: 8px;
  color: #616164;
  height: 30px;
}

/* Dropdown and slider adjustments */
.roleDropdown {
  width: 100%;
  padding: 5px;
  border-radius: 4px;
}

.stepSlider {
  width: 100%;
  height: 20px;
  border-radius: 4px;
  text-align: center;
  line-height: 20px;
}

/* Icons */
.icon,
.headerIcon {
  width: 17px;
  height: 17px;
  cursor: pointer;
}

.tableButtons {
  border: 1px solid transparent;
}
.tableButtons {
  display: flex;
  justify-content: flex-end;
  margin-right: 200px;
}

/* Modal Content */
.adminListModalTabs {
  display: flex;
  justify-content: center;
  height: 30px;
  border: 1px solid transparent;
}

.tabContainer {
  display: flex;
  background-color: #f8f9fa;
  border-radius: 8px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.tab {
  width: 80px;
  cursor: pointer;
  color: #616164;
  font-size: 13px;
  transition: background-color 0.3s ease, color 0.3s ease;
  text-align: center;
  padding-top: 4px;
}

.tab:hover {
  background-color: #e0e0e0;
  color: #af98db;
}

.activeTab,
.activeTab:hover {
  background-color: #af98db;
  color: #fff;
  border-radius: 4px;
}

/* Modal Content Table */
.accessListUserList td,
.adminListUserListTransfer td {
  height: 30px;
}
.accessListUserList th:nth-child(1),
.accessListUserList td:nth-child(1) {
  width: 10%;
  box-sizing: border-box;
  text-align: center;
}

.accessListUserList th:nth-child(2),
.accessListUserList td:nth-child(2) {
  width: 10%;
  box-sizing: border-box;
  text-align: center;
}

.accessListUserList th:nth-child(3),
.accessListUserList td:nth-child(3) {
  width: 40%;
  box-sizing: border-box;
  text-align: left;
}

.accessListUserList th:nth-child(4),
.accessListUserList td:nth-child(4) {
  width: 40%;
  box-sizing: border-box;
  text-align: left;
}

.accessListUserList th:nth-child(3):hover,
.accessListUserList th:nth-child(4):hover {
  background-color: #eeeeee;
}

.accessListGroupList td {
  height: 30px;
  border-bottom: 1px solid #e1e1e1;
}
.accessListGroupList th:nth-child(1),
.accessListGroupList td:nth-child(1) {
  width: 15%;
  box-sizing: border-box;
  text-align: center;
}

.accessListGroupList th:nth-child(2),
.accessListGroupList td:nth-child(2) {
  width: 15%;
  box-sizing: border-box;
  text-align: center;
}

.accessListGroupList th:nth-child(3),
.accessListGroupList td:nth-child(3) {
  width: 70%;
  box-sizing: border-box;
  text-align: left;
}

.accessListGroupList th:nth-child(2):hover,
.accessListGroupList th:nth-child(3):hover {
  background-color: #eeeeee;
}

/* Modal Content Buttons */
.accessModalButtons {
  transition: background-color 0.3s ease; /* Smooth background-color transition */
  border: 1px solid transparent;
  display: flex;
  justify-content: space-around;
  box-sizing: border-box;
}
.accessModalButton1 {
  background-color: white;
  color: #239b9c;
  border-radius: 4px; /* Rounded corners */
  cursor: pointer; /* Pointer cursor on hover */
  margin: 0;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  border: 1px solid #239b9c;
  box-sizing: border-box;
}

.accessModalButton2 {
  background-color: #f44336;
  color: white;
  border-radius: 4px; /* Rounded corners */
  cursor: pointer; /* Pointer cursor on hover */
  margin: 0;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  border: 1px solid #f44336;
  box-sizing: border-box;
}

.accessModalButton2:hover {
  background-color: #d7372b; /* Darker red on hover */
  color: white;
}
.accessModalFooterButtons {
  background-color: white;
  cursor: pointer;
  display: flex;
  justify-content: space-around;
  border: 1px solid transparent;
  width: 100%;
  box-sizing: border-box;
  transition: background-color 0.3s ease; /* Smooth background-color transition */
}
.accessModalFooterButton1 {
  color: white;
  border: 1px solid #239b9c;
  background-color: #239b9c;
  padding: 10px 20px;
  border-radius: 4px;
  box-sizing: border-box;
}
.accessModalFooterButton1:hover {
  color: white;
  background-color: #1d8585;
}
.accessModalFooterButton2 {
  border: 1px solid transparent;
  background-color: gray;
  color: white;
  padding: 10px 20px;
  border-radius: 4px;
  box-sizing: border-box;
}
.accessModalFooterButton2:hover {
  background-color: rgb(104, 104, 104);
}

.access-modal-cancel,
.access-modal-confirm {
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.access-modal-cancel {
  border: 1px solid #616164;
  color: #616164;
  margin-left: 40px;
}

.access-modal-confirm {
  border: 1px solid #e74c3c;
  color: #e74c3c;
  background-color: white;
  margin-right: 40px;
}

.access-modal-cancel:hover {
  background-color: #aaa;
  color: white;
}

.access-modal-confirm:hover {
  background-color: #c0392b;
  color: white;
}

.access-modal-content-delete {
  background-color: white;
  border-radius: 5px;
  height: 25vh;
  width: 300px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
  padding-bottom: 20px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  border: 1px solid transparent;
  display: flex;
  justify-content: center;
  margin-top: 25px;
}
.access-close {
  position: absolute;
  top: 10px;
  right: 13px;
  cursor: pointer;
  font-size: medium;
}
.access-close:hover,
.access-close:focus {
  color: rgb(192, 32, 32);
  text-decoration: none;
  cursor: pointer;
}

.radioButton {
  appearance: none;
  -webkit-appearance: none;
  width: 16px;
  height: 16px;
  border: 2px solid #9e8bc3;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  outline: none;
  cursor: pointer;
}

.radioButton:checked {
  background-color: transparent;
  border-color: #9e8bc3;
}

.radioButton:checked::after {
  content: "";
  width: 8px;
  height: 8px;
  background-color: #9e8bc3;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
