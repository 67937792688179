.canvas {
  /* flex: 1; */
  display: flex;
  flex-direction: column;
  /* overflow: hidden; */
  background-color: white;
  width: 100%;
  height: 100%;
}

.canvasStyle {
  /* flex: 1; */
  position: relative;
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  height: calc(100% - 3.5rem);
  flex-wrap: wrap;
  border-top: 2px solid rgba(224, 224, 224, 1);
  /* width: calc(99% - 15vw); */
  /* scrollbar-gutter: stable; */
}

.draggableBox {
  border: 1px solid #2bb9bb;
  max-width: 300px;
  margin: 20px;
  display: inline-block;
  font-size: 14px;
  /* height: fit-content; */
}

.bottomBar {
  height: 3.5rem;
  /* background-color: #f0f0f0; */
  border-top: 2px solid rgba(224, 224, 224, 1);
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
  z-index: 99;
  background-color: white;
}

.draggableTable {
  display: inline-block;
  margin: 20px;
}

.IconDataset {
  position: absolute;
  left: 10px;
  top: 3.5%;
}

.draggableBoxTitle {
  /* text-align: center; */
  color: white;
  cursor: move;
  background-color: #2bb9bb;
  padding: 5px 0;
  display: flex;
}

.columnBox {
  position: relative;
  padding: 5px;
  border-top: 1px solid #2bb9bb;
  /* border-top: 1px solid #d7d9db; */
  display: flex;
}

.columnItem {
  padding: auto;
  cursor: pointer;
}

.ellip {
  flex: 1;
  padding: auto 10px;
  text-align: left;
}

.ellip:hover {
  white-space: normal;
  /* overflow: hidden; */
  text-overflow: unset;
  width: fit-content;
  text-align: left;
  /* background-color: white; */
}

.connectPoint {
  opacity: 0.5;
  background-color: #a9a9a9;
}

.connectPoint:hover {
  background-color: grey;
  opacity: 1;
}

.filter_dataset {
  border-left: 2px solid rgba(224, 224, 224, 1);
  text-align: center;
  position: absolute;
  display: flex;
  padding-top: 1rem;
  height: 100%;
  width: 211px;
  background-color: white;
  right: 0;
  /* overflow: auto; */
  overflow-x: hidden;
  overflow-y: auto;
  z-index: 90;
  border-right: none;
}