:root{
	--closeButtonColor: #484848;
}

.axisField {
	width: 100%;
	/* width:95%; */
	/* margin: 4px 4px 4px 4px; */
	/* padding: 8px 0 6px 10px; */
    /* margin-top: 6px; */
	align-content: center;
	font-size: 11px;
	text-align: start;
	display: flex;
	cursor: move;
	border-radius: 2px;
	background-color: white;
	border: 1px solid rgb(211, 211, 211);
	color: rgb(87, 87, 87);
	/* height: 24px; */
}
.axisField:hover {
	border: 1px solid #2bb9bb;
	color: #2bb9bb;
    
	/* font-weight: 600; */
}

.buttonCommon {
	font-size: 13px;
	padding: 5px 3px;
	margin: 0;
	border: none;
}

.columnClose {
	color: var(--closeButtonColor);
	background-color: transparent;
	border-radius: 3px 0 0 3px;
	/* border-radius: 4px 0 0 4px; */
	cursor: pointer;
	display: flex;
	justify-content: center;
	align-items: center;
}

.columnClose:hover {
	color: red;
	/* background-color: lightgray; */
}

.columnDown {
	color: #999999;
	background-color: transparent;
	border-radius: 0 4px 4px 0;

	cursor: pointer;
	display: flex;
	justify-content: center;
	align-items: center;
}

.columnDown:hover {
	color: rgb(97, 97, 97);
	/* background-color: #f4f4f4; */
}

.columnName {
	flex: 1;
	font-size: 13px;
	padding: 5px 4px 2px 2px;
	margin: 0;
	margin-top: 2px;
	/* margin-right: 5px; */
	/* margin-left: 2px; */
	border: none;
	font-weight: 600;

	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	line-height: 10px;
	text-align: left;
	/* height: 24px; */
}
.columnPrefix {
	font-size: 11px;
	color: #5502fb;
	padding: 4px 2px;
	white-space: nowrap;

	max-width: 30%;

	overflow: hidden;
	text-overflow: ellipsis;
	text-align: right;
}


/*// Window Function Styles //*/
.MenuSize{
	margin-top: 13px; 
	width: 190px;
}

.standingBtn{
	display: flex;
	text-align: center;
	color: rgb(87, 87, 87);
}

.standingslidingBtn{
	display: flex;
	text-align: center;
	margin-top: 3px;
	color: rgb(87, 87, 87);
}

.standingMain{
	display: flex; 
	flex-direction: column;
}

.commonOption1{
	display: flex; 
	margin-top: 3px;
}

.commonOption2{
	display: flex;
}

.slidingRefWindow{
	display: flex; 
	margin-top: 0; 
	padding-top: 0; 
	gap: 5px;
}

.commonOption3{
	display: flex; 
	flex-direction: column;
}

.slidingPrevious{
	display: flex; 
	font-size: 12px; 
	padding-left: 15px; 
	color: rgb(96, 96, 96); 
}

.slidingNext{
	display: flex; 
	font-size: 12px;
	padding: 0 15px 0 10px; 
	color: rgb(96, 96, 96);
}

.standingSlidingPrevious{
	display: flex;
	font-size: 12px;
	color: rgb(96, 96, 96);
}

.standingSlidingNext{
	display: flex;
	font-size: 12px;
	padding: 0 15px 0 2px; 
	color: rgb(96, 96, 96);
}

.slideDirection {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 1.5rem;
	flex: 1;
	border: 1px solid rgba(224,224,224,1);
	font-size: 12px;
	background-color: #fff;
	text-transform: capitalize;
	cursor: pointer;
	transition: 0.2s;
}

.slideDirectionSelected {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 1.5rem;
	flex: 1;
	border: 1px solid rgba(224,224,224,1);
	font-size: 12px;
	background-color: rgba(224,224,224,1);
	text-transform: capitalize;
	cursor: pointer;
	transition: 0.2s;
}

.canelOkBtn{
	display: flex; 
	justify-content: space-between; 
	margin: 14px 15px 6px 15px;
}

.cancelBtn{
	color: black;
}

.okBtn{
	color: #af99db;
}

.windowFnStandingSlidingRefWindow{
	border: 1px solid rgba(224,224,224,1);
	border-radius: 2px;
	margin: 3px 15px 0 15px;
	padding-top: 3px;
}



