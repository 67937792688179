.adminContainer {
  border: 1px solid transparent;
  background-color: white;
  padding-bottom: 2rem;
  padding-top: 30px;
  padding: 0px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  height: 100%; /* Ensures the container takes up full viewport height */
  width: 100%;
}

.adminAddButton{
  color:#ac9bf8 ;
  border: 1px solid #ac9bf8 ;
}

.adminAddButton:hover {
  background-color: #ac9bf8;
  color: whitesmoke;
}

.adminAddIconHover {
  display: none;
}

.adminAddButton:hover .adminAddIconDefault {
  display: none;
}

.adminAddButton:hover .adminAddIconHover {
  display: block;
}

.user-linkButton {
  text-decoration: none;
}

.adminAddButton img,
.adminAddIconHover img {
  margin-right: 15px;
  margin-bottom: 0px;
}

/* Table */
.adminTable th {
  color: #ac9bf8;
}

.adminTable th:nth-child(3):hover,
.adminTable th:nth-child(4):hover {
  background-color: #eeeeee;
}

.adminTable th:nth-child(1),
.adminTable td:nth-child(1) {
  width: 5%;
  box-sizing: border-box;
  display: none;
}

.adminTable th:nth-child(2),
.adminTable td:nth-child(2) {
  width: 5%;
  box-sizing: border-box;
  padding-top: 6px;
}

.adminTable th:nth-child(3),
.adminTable td:nth-child(3) {
  width: 40%;
  box-sizing: border-box;
}

.adminTable th:nth-child(3) {
  padding-left: 32px;
}

.adminTable td:nth-child(3) {
  text-align: left;
}

.adminTable th:nth-child(4),
.adminTable td:nth-child(4) {
  width: 40%;
  box-sizing: border-box;
}

.adminTable td:nth-child(4) {
  text-align: left;
}

.adminTable th:nth-child(5),
.adminTable td:nth-child(5) {
  width: 10%;
  box-sizing: border-box;
}

.adminTable th:nth-child(5) {
  text-align: center;
  padding-right: 0px;
}

.adminTable td:nth-child(5) {
  width: 9.5%;
}

.user-headerIcon {
  position: absolute;
  right: 10px;
  bottom: 5;
  width: 20px;
  height: 18px;
}

.adminTable tbody tr {
  background-color: #fff; /* Default background color for all rows */
}

/*.adminTable tbody tr:nth-child(odd) {
    background-color: #f0f0f0;  Background color for odd rows 
} 

.adminTable tbody tr:hover {
    background-color: #b9b9b9;  Background color on hover 
} */

.user-img-icon {
  width: 20px;
  height: 20px;
}

.modalHeader {
  height: 35px;
  background-color: #af98db;
  color: #ffffff;
  font-weight: 500;
  border:1px solid #af98db;  
}
.modalConfirm{
  margin-right:100px;
   
}

.userSearch {
  height: 30px;
  border-radius: 4px;
  border:1px solid #eeeeee;
  margin-bottom:30px;
}
.groupSearchIcon {
  position: relative;
  right: 23px;
  top: 5px;
}

.close {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  height: 20px;
  width: 20px;
}

.userList {
  max-height: 400px;
  overflow-y: auto;
}

.userItem {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.tabContainer button:hover {
  background-color: #2bb9bb;
  color:white;
}
.tabContainer button::after {
  background-color: #2bb9bb;
  color: white;
}

.userItem img {
  margin-right: 20px;
  margin-left: 50px;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.modalCancel {
  color: #616164;
  border: 1px solid #616164;
  background-color: #ffffff;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  margin-left: 100px;
  margin-top:20px;
}
.modalCancel:hover {
  background-color: #616164;
  color: white;
  border: 1px solid #616164;
}
.modalConfirm {
  color: red;
  border: 1px solid transparent;
  background-color: #ffffff;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  margin-left: 100px;
  margin-top:20px;
}
.modalConfirm:hover {
  background-color: red;
  color: white;
}

/* Modal Content */
.adminListModalTabs {
  display: flex;
  justify-content: center;
  height: 30px;
  border: 1px solid transparent;
}

.tabContainer {
  display: flex;
  background-color: #f8f9fa;
  border-radius: 8px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.tab {
  width: 80px;
  cursor: pointer;
  color: #616164;
  font-size: 13px;
  transition: background-color 0.3s ease, color 0.3s ease;
  text-align: center;
  padding-top: 4px;
}

.tab:hover {
  background-color: #e0e0e0;
  color: #af98db;
}

.activeTab,
.activeTab:hover {
  background-color: #af98db;
  color: #fff;
  border-radius: 4px; 
}

.scrollableTableBody {
  margin-top: 20px;
  margin-right: 20px;
  overflow-y: auto;
}

.headerText {
  display: block;
  text-align: center;
  width: 100%;
}

.adminlistHead {
  position: relative; /* Make the header a positioning context */
}

.adminlistHead img {
  position: absolute;
  right: 10px; /* Adjust this value if needed */
  top: 50%;
  transform: translateY(-50%); /* Vertically center the icon */
}

/* Modal Content Table */
.adminListUserList td,
.adminListUserListTransfer td {
  height: 30px;
}
.adminListUserList th:nth-child(1),
.adminListUserList td:nth-child(1) {
  width: 10%;
  box-sizing: border-box;
  text-align: center;
}

.adminListUserList th:nth-child(2),
.adminListUserList td:nth-child(2) {
  width: 10%;
  box-sizing: border-box;
  text-align: center;
}

.adminListUserList th:nth-child(3),
.adminListUserList td:nth-child(3) {
  width: 40%;
  box-sizing: border-box;
  text-align: left;
}

.adminListUserList th:nth-child(4),
.adminListUserList td:nth-child(4) {
  width: 40%;
  box-sizing: border-box;
  text-align: left;
}

.adminListUserList th:nth-child(3):hover,
.adminListUserList th:nth-child(4):hover {
  background-color: #eeeeee;
}

.adminListGroupList td {
  height: 30px;
  border-bottom: 1px solid #e1e1e1;
}
.adminListGroupList th:nth-child(1),
.adminListGroupList td:nth-child(1) {
  width: 20%;
  box-sizing: border-box;
  text-align: center;
}

.adminListGroupList th:nth-child(2),
.adminListGroupList td:nth-child(2) {
  width: 40%;
  box-sizing: border-box;
  text-align: left;
}

.adminListGroupList th:nth-child(3),
.adminListGroupList td:nth-child(3) {
  width: 40%;
  box-sizing: border-box;
  text-align: left;
}

.adminListGroupList th:nth-child(2):hover,
.adminListGroupList th:nth-child(3):hover {
  background-color: #eeeeee;
}

.adminListUserListTransfer th:nth-child(1),
.adminListUserListTransfer td:nth-child(1) {
  width: 20%;
  box-sizing: border-box;
  text-align: center;
}

.adminListUserListTransfer th:nth-child(2),
.adminListUserListTransfer td:nth-child(2) {
  width: 40%;
  box-sizing: border-box;
  text-align: left;
}

.adminListUserListTransfer th:nth-child(3),
.adminListUserListTransfer td:nth-child(3) {
  width: 40%;
  box-sizing: border-box;
  text-align: left;
}

.adminListUserListTransfer th:nth-child(2):hover,
.adminListUserListTransfer th:nth-child(3):hover {
  background-color: #eeeeee;
}
