.datasetListPopover {
	padding: 1rem 1rem 2rem 1rem;
}

.dataSetNameIndi {
	border: 1px solid transparent;
	margin: 0 1rem;
	padding: 0.25rem 1rem;
	cursor: pointer;
}

.datasetListPopoverHeading {
	margin-bottom: 1rem;
	font-weight: 600;
	display: flex;
}

.dataSetNameIndi:nth-child(odd) {
	background-color: rgb(245, 245, 245);
}

.dataSetNameIndi:hover {
	border: 1px solid lightgray;
}

.loaderContainer {
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	width: 100%;
	height: 100%;
	display: flex;
	background-color: rgba(0, 0, 0, 0.3);
	z-index: 2000;
}

.loader {
	display: flex;
	justify-content: center;
	align-items: center;
	border: 3px solid #dbdbdb; /* Light grey */
	border-top: 3px solid #0076f6; /* Blue */
	border-radius: 50%;
	width: 2.5rem;
	height: 2.5rem;
	animation: spin 1.5s cubic-bezier(0.65, 0.05, 0.36, 1) infinite;
	margin: auto;
}
@keyframes spin {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
