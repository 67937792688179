.calculationCreateButton {
  border: 1px solid #2bb9bb;
  margin: 10px 0; 
  background-color: transparent;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 600;
  border-radius: 3px;
  width: 100%;
  height: 30px;
  margin: 10px 10px;
  color: #2bb9bb;
  font-size: 16px;
  cursor: pointer;
  position: relative;
  /* width: 90%; */
}

.calculationCreateButton:hover {
  background-color: #2bb9bb;
  border: 1px solid #2bb9bb;
  color: white;
}
.savedcalculation:hover{
  overflow-y: scroll;
}