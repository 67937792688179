.colorStepsContainer {
	width: 100%;
	padding: 10px 0 0 0;
	font-size: 12px;
	display: flex;
	flex-direction: column;
	transition: ease-in 0.3s linear;
	overflow: auto;
	height: 100%;
}

.colorStepsInput {
	margin: 2px;
	height: 1.5rem;
	flex: 1;
	display: flex;
	padding: 1px;
	border-radius: 3px;
}

.colorIndicator {
	height: 23px;
	max-width: 23px;
	border-color: grey;
	border-radius: 3px;
	border: 1px solid;
	flex: 1;
	margin-top: 1px;
}

.colorStepsList {
	display: flex;
	flex-direction: column;
	padding-left: 15px;
	padding-right: 10px;
}

.colorStepsAddDelete {
	display: flex;
	float: right;
	width: 50px;
	margin-left: 10px;
}
.colorScaleContainer {
	margin: 10px;
	padding: 0px 5px 5px 5px;
	text-align: left;
	color: rgb(96, 96, 96);
	font-weight: 600;
}

.inputFieldContainer {
	display: flex;
	column-gap: 20px;
	padding: 8px 2px 8px 12px;
}
