/* input[type="file"]{
    opacity:0;
    width:0;
}

/* label{
    background-color: rgba(224,224,224,1);
    padding:10px;
    border-radius:5px;
} */
/*.labelStyle{
background-color: rgba(224, 224, 224, 1);
    padding: 10px;
    border-radius: 5px;
} */

/* Flat File Upload */
.FileUploadContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 500px;
  margin: 0 auto;
  text-align: left;
  padding: 2rem;
  border: 1px solid transparent;
}

.uploadFileTitle {
  font-size: 16px;
  font-weight: bold;
  margin: 10px;
  padding-bottom: 50px;
  color: #5d5c5c;
}

.file-upload-button-container {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
}
.header {
  font-size: 1.25rem;
  white-space: nowrap;
  width: 200px;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-weight: 500;
  height: 32px;
  letter-spacing: 0.0075em;
  color: #b4b4b3;
  padding-bottom: 10px;
  margin-left: 30px;
}
.rightsidebar-container {
  border: 1px solid transparent;
  display: flex;
  justify-content: center;
  text-align: center;
  width: 265px;
  padding-right: -3px;
}
.icon-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid transparent;
  padding-bottom: 10px;
  width: 265px;
}
.csv-icon,
.json-icon,
.excel-icon {
  display: flex;
  text-align: left;
  margin-left: 4px;
  padding-top: -2px;
  color: #5d5c5c;
}
.csv-img {
  width: 19px;
  height: 19px;
}
.json-img,
.excel-img {
  width: 17px;
  height: 17px;
}
.csv-container p,
.json-container p,
.excel-container p {
  display: flex;
  align-items: left;
  justify-content: left;
  text-align: left;
  font-size: 14.5px;
  margin-right: 0px;
  font-weight: 500;
}
.csv-container,
.json-container,
.excel-container {
  border: 1px solid transparent;
  width: 200px;
  height: 45px;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  margin-bottom: 10px;
  color: #9e9e9e;
  cursor: pointer;
  transition: 0.2s;
  display: flex;
  align-items: center;
  text-align: left;
  column-gap: 0.5rem;
  padding: 6px 6px 6px 8px;
  border-radius: 4px;
  white-space: nowrap;
}
.csv-container:hover,
.json-container:hover,
.excel-container:hover {
  border: 1px solid rgba(224, 224, 224, 1);
  border-radius: 4px;
  cursor: pointer;
}
.csv-container:active,
.json-container:active,
.excel-container:active {
  border: 1px solid #2bb9bb;
  border-radius: 4px;
}
.csv-container.selected-container:hover,
.json-container.selected-container:hover,
.excel-container.selected-container:hover {
  border: 1px solid #2bb9bb;
}
.selected-container {
  border: 1px solid #2bb9bb;
  border-radius: 4px;
}
.select-container {
  display: flex;
  justify-content: center;
  padding-top: 20rem;
  width: 100%;
  color: #b4b4b3;
  font-size: 20px;
  margin: 0;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 1.6;
  letter-spacing: 0.0075em;
  color: #b4b4b3;
}
.dialog-container {
  border: 1px solid transparent;
  min-width: 300px;
  max-width: 350px;

  height: auto;
  border-radius: 5px;
  overflow: hidden;
}
.dialog-title {
  font-size: 1.1em;
  font-weight: 500;
  color: rgb(211, 25, 25);
  margin-left: -6px;
  padding-bottom: 10px;
}
.excelContainer {
  display: flex;
  align-items: center;
  border: 1px solid transparent;
  justify-content: center;
  text-align: center;
  display: flex;
  position: relative;
  white-space: nowrap;
  margin-top: 15px;
}
.sheetNameLabel {
  font-size: 13px;
  margin-right: 10px;
}
.sheetNameInput {
  padding: 7px;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-size: 12px;
  min-width: 100px;
  transition: border-color 0.3s ease-in-out;
}
.sheetNameInput:focus {
  outline: 0;
  border-color: gray;
  transition: border-color 0.3s ease-in-out;
}

/* File Drop Zone */
#form-file-upload {
  height: 14rem;
  border: 2px dashed rgba(224, 224, 224, 1);
  display: flex;
  padding: 1rem;
  flex-direction: column;
}
#input-file-upload {
  display: none;
}
.upload-button {
  background-color: #af99db;
  color: white;
  padding: 10px;
  border-radius: 5px;
  border-color: transparent;
  margin: 0 auto;
  width: fit-content;
  transition: background-color 0.3s ease, color 0.3s ease;
}
.upload-button:hover {
  cursor: pointer;
  background-color: #8c6bb1;
}
#drag-file-element {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 1rem;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}

.fileNameCard {
  border: 1px solid rgba(224, 224, 224, 1);
  margin: 10px auto;
  padding: 8px;
  font-size: 12px;
  display: flex;
  width: fit-content;
  font-size: 12px;
}
.input-style {
  display: block;
  width: 200px;
  margin-left: 10px;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 5px;
  flex: 1;
  margin-top: -5px;
}
.label-style {
  display: block;
  white-space: nowrap;
  font-weight: 500;
  color: #5d5c5c;
}
.sheet-name-container {
  display: flex;
  margin-top: 150px;
}

/* Edit Flat File */
.columnNameStyle {
  width: 148px;
  color: rgb(70, 71, 71);
}

.columnNameStyle:hover {
  border: 1.5px solid rgba(224, 224, 224, 1);
  border-radius: 4px;
  width: 148px;
}

input[id="columnName"] {
  width: 148px;
  height: 24px;
  border: 1px solid rgba(224, 224, 224, 1);
  border-radius: 4px;
  padding-left: 5px;
}

.editFlatFileContainer {
  /* width: 100%; */
  /* height: 100%; */
  padding: 2rem 0.8rem;
  display: flex;
  flex-direction: column;
}

.editFlatFileHeader {
  flex: 1;
  display: flex;
  justify-content: space-between;
  width: 90%;
  max-height: 7rem;
  margin: 0px auto 2rem auto;
}
.flat-file-name {
  flex: 1;
  text-align: left;
}

.tableName-style {
  padding: 5px 10px;
  width: 60%;
}

.tableName-style:hover {
  border: 1px solid grey;
  border-radius: 5px;
}

.format-elm-container {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.formatElm {
  flex: 1;
  display: flex;
  gap: 2px;
  max-height: 28px;
}

.buttonContainer {
  flex: 1;
  display: flex;
  width: 90%;
  /* margin: 1rem auto; */

  justify-content: right;
  gap: 15px;
}

/* final flatfile data */
.confirm-flat-file-container {
  display: grid;
  grid-template-rows: 20% 70% 10%;
  height: 100vh;
  padding: 2rem;
  width: 100vw;
}

.iconDefault {
  margin-left: 5px;
  cursor: pointer;
  margin-top: 4px;
  color: #cccccc; /* Light grey color */
  transition: color 0.3s, font-size 0.3s; /* Transition for smooth hover effect */
}

.iconHover {
  color: #8bc34a; /* Medium light green color on hover */
  font-size: calc(1em - 2px); /* Reduce size by 2 pixels on hover */
}

.ffTableDiv {
  flex: 1;
  height: calc(100% - 6rem);
  width: 90%;
  /* margin: 1rem auto; */
}

.ffTablecellDiv {
  display: flex;
  justify-content: space-between;

  margin-bottom: 10px;
}
.ffDialogTablecell {
  font-weight: bold;
  background-color: #e8eaf6;
}
