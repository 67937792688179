body {
  margin: 0;
  font-family: "Roboto-Regular", "Roboto-Medium", "Roboto-Bold", "Roboto-Light",
    -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.ellipsis {
  white-space: nowrap;        
  overflow: hidden;           
  text-overflow: ellipsis;    
  /* width: 200px;                */
}

code {
  font-family: 'Roboto-Light', 'Segoe UI', source-code-pro, Menlo, Monaco, Consolas, 'Times New Roman',
    monospace;
}
.hideScrollBar {
  overflow-y: auto; /* Allows vertical scrolling */
  scrollbar-width: none; /* Firefox */
}
.hideScrollBar::-webkit-scrollbar {
  display: none; /* Chrome, Safari, and Edge */
}
/**
  * Disable button
  */
.disabled{
  pointer-events: none; /* Prevents click, hover, and other interactions */
  opacity: 0.5; /* Makes it visually look disabled */
  cursor: not-allowed;
}
.disabled.disabled-light {
  color: #9e9e9e !important; 
}
.disabled.disabled-dark { 
  color: #757575 !important; 
}
.hidden{
  display: none;
}
.text-primary {
  color: #484848;
}