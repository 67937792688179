
.ShowDataPopupKey {
  /* font-weight: bold; */
  width: 45%;
  text-align: right;
  word-wrap: break-word;
  color: #616164;
}

.ShowDataPopupValue {
  width: 55%;
  word-wrap: break-word;
  text-overflow: ellipsis;
  text-align: left;
  color: rgb(51, 51, 51);
}

.CrossTab {
  height: 95%;
  /* overflow: auto; */
}

.CrossTabTableHeader::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.CrossTabTable {
  display: table;
  table-layout: fixed;
  width: max-content;
  z-index: 1;
}

.CrossTabHeader,
.CrossTabLeftColumnHeader {
  border: 1px solid rgb(222, 222, 222);
  /* background-color: rgb(216, 215, 215); */
  background-color: rgb(247, 247, 247);
  cursor: default;
  font-weight: bold;
  font-size: 12px;
  white-space: nowrap;
  padding-left: 8px;
  padding-right: 4px;
  text-align: center;
  text-overflow: ellipsis;
}

.CrossTabLeftColumnHeader {
  left: 0;
  top: auto;
  text-align: left;
}

.TableHeader {
  border: 1px solid rgb(222, 222, 222);
  /* background-color: rgb(216, 215, 215); */
  background-color: rgb(247, 247, 247);
  cursor: default;
  font-weight: bold;
  font-size: 12px;
  white-space: nowrap;
  padding-left: 8px;
  padding-right: 4px;
  text-align: center;
  text-overflow: ellipsis;
  left: 0;
  top: auto;
  text-align: left;
}

.CrossTabRow {
  line-height: 1.2em;
}

.CrossTabCell {
  border: 1px solid rgb(222, 222, 222);
  font-size: 11px;
  text-overflow: ellipsis;
  white-space: nowrap;
  /* padding-left: 3px;
	padding-right: 3px; */
  padding: 0 8px 0 4px;
  text-align: right;
  height: auto;
  width: auto;
}

.EmptyHeaderCell {
  /* background-color: #fff; */
  /* background-color: rgb(216, 215, 215); */
  background-color: rgb(247, 247, 247);
  border: 1px solid rgb(222, 222, 222);
  cursor: default;
}

.UserClickedCell {
  background-color: red;
}

.UserClickedCellChildren {
  background-color: #f7e8fa;
  opacity: 1;
  font-weight: bold;
}

.UserClickedCellRemainingChildren {
  opacity: 0.2;
}

.simpleCardStyle {
  border: 2px solid grey;
}

.container {
  width: 100%;
  height: 100%;
  position: relative;
}

.resizeable {
  position: absolute;
  border: 2px solid grey;
  max-width: 200px;
  max-height: 200px;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 150px;
  min-height: 150px;
}

.resizer {
  position: absolute;
  background: rgba(224, 224, 224, 1);
  /* background: rgb(199, 122, 122); */
}

.resizer-r {
  cursor: col-resize;
  height: 100%;
  right: 0;
  top: 0;
  width: 2px;
}

.resizer-t {
  cursor: row-resize;
  height: 2px;
  left: 0;
  top: 0;
  width: 100%;
}

.resizer-b {
  cursor: row-resize;
  height: 2px;
  left: 0;
  bottom: 0;
  width: 100%;
}

.resizer-l {
  cursor: col-resize;
  height: 100%;
  left: 0;
  top: 0;
  width: 2px;
}

.simpleCardParentContainer {
  height: 200px;
  width: 200px;
}
