.link{
  display: inline;
  position: relative;
}
.link-popup{
    position: absolute;
  left: 0;
  display: flex;
  align-items: center;
  background-color: white;
  padding: 6px 10px;
  gap: 10px;
  border-radius: 6px;
  border: 1px solid lightgray;
  width: fit-content;
}
img{
  height: 15px;
}

#url-input{
  margin-bottom: 15px;
  padding: 10px;
  border: 1px solid #E0E0E0;
  border-radius: 7px;
}

#url-input:focus {
  border: 1px solid #2bb9bb;
  outline: #2bb9bb;
}