.dm-ChartControls-style{
    color:  #404040;
    font-weight: 600;
    padding: 10px 0 0 0.5rem;
    margin-bottom: 3px;
    text-align: center;
}

.dm-tableviewer-bottom{
    flex: 1;
            display: flex;
            align-items: center;
            justify-content: center;
}

